.faq-title {
  color: #0d3b66;
}

.faq-main {
  border-radius: 1rem;
}

.accordion-box-border {
  border-radius: 1.6rem !important;
}
