.contactHero {
  position: relative;
}

.contactHeroImg {
  /* background-image: url("/public/assets/contact/contactBG.png"); */
  display: block;
  /* z-index: -1; */
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  background-size: cover;
  height: 90vh;
  border-radius: 8rem;
  background-position: center;
  background-repeat: no-repeat;
}

.contactHeroTagline {
  position: absolute;
  text-transform: uppercase;
  /* z-index: 1; */
  width: 70%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contactHeroTagline h1 {
  color: #ffffff;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
}

.submitConFBtn:hover {
  transform: scale(1.1);
  transition: transform 1s ease-in-out;
}

@media (max-width: 767px) {
  .contactHeroImg {
    height: 40vh;
    border-radius: 3rem;
    width: 95%;
  }
  /* .contactHeroTagline h1 {
      font-size: rem;
    } */

  .contactHero h1 {
    font-size: small;
  }
}

/**/

.inputInfoAll::placeholder {
  color: #978f8f;
}
.inputInfoAll:focus::placeholder {
  color: rgb(220, 217, 217);
}

/**/

.conForm-Main {
  display: flex;
  background-color: rgb(255, 255, 255);
  width: 100%;
  justify-content: center;
  padding: 0;
}

.conForm-Main2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4rem;
}

.conInfo {
  color: aliceblue;
  background-color: #d2ab67;
  padding: 3rem;
  border-radius: 5.4rem;
  z-index: 2;
}

.conInfo-Head {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1.5rem;
}

.conInfo-TagLine {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1rem;
}

.conForm-Detail {
  border-radius: 5.4rem;
  background-color: #d2ab67;
  padding: 3rem;
  margin-left: -7rem;
  z-index: 1;
  text-transform: uppercase;
}
.conForm-Detail2 {
  margin-top: 2rem;
  margin-left: -6rem;
  margin-right: 1rem;
  padding-left: 12rem !important;
}

.inputInfoAll {
  border-radius: 0.7rem;
  
}
.sendMessageBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitConFBtn {
  padding: 0.5rem 1.4rem;
  border-radius: 1rem;
  background-color: #07081e;
  border-color: #07081e;
  text-transform: uppercase;
}

.submitConFBtn:hover {
  background-color: #07081e;
  border-color: #16172e;
  box-shadow: -1px 1px 25px -11px rgba(0, 0, 0, 0.88);
}

.conIcons1 {
  display: block;
  margin-top: 2rem;
}
.conAdd1 {
  text-align: inherit;
}
.conAdd12 {
  color: white;
  text-decoration: none;
}
.conAdd12:hover {
  color: #4c91f8;
}
.socialIcons {
  margin-top: 3rem;
  background-color: rgb(255, 255, 255);
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.socialIcons2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconsList {
  color: #07081e;
}
.iconsList2 {
  font-size: 2em;
  padding: 1rem 0;
}
.ContactTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: xx-large;
  font-weight: bolder;
}
/**/

@media (max-width: 767px) {
  .conForm-Main2 {
    flex-direction: column;
    margin: 0 2rem;
  }
  .conForm-Detail {
    margin-left: 0;
    border-radius: 3rem;
    margin-bottom: 3rem;
  }
  .conForm-Detail2 {
    margin-left: 0;
    padding-left: 0 !important;
  }
  .conInfo {
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    border-radius: 3rem;
  }
  .ContactTitle {
    font-size: large;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1038px) {
  .rClass1 {
    display: block;
  }
  .rClass2 {
    display: block;
  }
  .conInfo-Head {
    font-size: 1rem;
  }
  .conInfo-TagLine {
    font-size: 0.7rem;
  }
  .conForm-Main2 {
    margin: 2rem;
  }
  .ContactTitle {
    font-size: x-large;
  }
  .conInfo {
    border-radius: 5rem;
  }
  .conForm-Detail {
    border-radius: 5rem;
  }

  .contactHeroImg {
    height: 60vh;
    border-radius: 5rem;
    width: 95%;
  }

  .contactHeroTagline h1 {
    font-size: medium;
  }
}

@media only screen and (min-width: 320px) and (max-width: 670px) {
  .conForm-Detail2 {
    padding: 0rem;
    display: contents;
  }
  .conInfo-Head {
    font-size: 1.5rem;
  }
  .conInfo-TagLine {
    font-size: 0.7rem;
  }
  .rClass1 {
    display: block;
  }
  .rClass2 {
    display: block;
  }
}

/* @media only screen and (min-width: 1200px) {
    .conForm-Main2 {
      max-width: 1400px;
      margin: 0 auto;
    }
  } */

/* @media only screen and (min-width: 1600px) {
    .conForm-Main2 {
      max-width: 1600px;
    }
  } */
