/* @media screen and (min-width:576px){
    .carousel-inner{
        display: flex;
    }
    .carousel-item{
        display: block;
        margin-right: 0;
        flex: 0 0 calc(100/3);
    }
}

.carousel-inner{
    padding: 1em;
}
.card{
    margin: 0 .5em;
} */

.btnStyle {
  border: 2px solid #0d3b66;
  color: #0d3b66;
  /* width: fit-content; */
  width: 100%;
}

.btnStyle:hover {
  background-color: #0d3b66;
  color: white;
  /* transform: scale(1.05);
  transition: transform 1s ease-in-out; */
}

@media only screen and (max-width: 768px) {
  .card {
    margin: 1rem 2rem;
  }
}
