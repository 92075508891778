.section__padding {
  padding: 4rem 4rem;
}

.footer {
  background-color: #0d3b66;
}

.sb__footer {
  display: flex;
  flex-direction: column;
}

.sb__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.sb__footer-links_div {
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

a {
  color: rgb(175, 175, 179);
  text-decoration: none;
}

.sb__footer-links_div h4 {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.9rem;
}

.sb__footer-links_div p {
  font-size: 12px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
}

.sb__footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.2rem;
}

.sb__footer-below-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sb__footer-below-links p {
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  color: white;
  font-weight: 600;
}

hr {
  color: white !important;
  width: 100%;
}

.sb__footer-copyright p {
  font-size: 13px;
  line-height: 15px;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.footer-head {
  color: #d2ab67;
}

@media only screen and (max-width: 768px) {
  .sb__footer-links {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .sb__footer-links-image {
    padding-bottom: 2rem;
  }
  .footer-image {
    width: 150px;
  }
  .section__padding {
    padding: 2rem 2rem;
  }
}
