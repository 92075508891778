.aboutUsImage {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.aboutUsContainer {
  background-color: #d2ab67;
  /* border-radius: 3rem; */
}

.aboutUsText {
  font-size: 18px;
  padding: 1rem;
}

.card-body {
  padding: 1rem;
}

@media only screen and (max-width: 1200px) {
  /* .imageDisplayText {
    padding: 2rem 0rem;
  } */
}

@media only screen and (max-width: 992px) {
  /* .imageDisplayText {
    padding: 2rem 0rem;
  } */
}

@media only screen and (max-width: 768px) {
  .aboutUsMain {
    padding: 0px 24px;
  }
  .aboutUsContainer {
    border-radius: 1rem;
  }
  .aboutUsImage {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .aboutUsText {
    font-size: 15px;
    padding-top: 0rem;
  }
}

@media only screen and (max-width: 576px) {
  .aboutBodyMain {
    padding: 0rem 2rem !important;
  }
}

@media only screen and (max-width: 475px) {
  .aboutUsText {
    font-size: 13px;
    padding: 0rem;
  }
  .aboutBodyMain {
    padding: 0rem !important;
  }
}
