.image-container {
  width: 100%;
}

.container {
  border-radius: 3rem;
}

.image-content {
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 55%;
  border-top-right-radius: 1rem;
}

.imageDisplayContainer {
  background-color: #d2ab67;
  border-radius: 3rem;
  color: white;
  font-size: 18px;
}

.row > * {
  padding: 0rem;
}

.imageDisplayMain {
  margin: 0rem 3.5rem;
}

.imageDisplayText {
  padding: 2rem 3rem;
}

.imageDisplay {
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  /* .imageDisplayText {
    padding: 2rem 0rem;
  } */
}

@media only screen and (max-width: 992px) {
  .imageDisplay {
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .imageDisplayMain {
    margin: 0rem 2rem;
  }
  .imageDisplayContainer {
    font-size: 16px;
  }
}

@media only screen and (max-width: 567px) {
  .imageDisplayContainer {
    border-radius: 2rem;
    font-size: 14px;
  }
  .imageDisplay {
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
  }
  .imageDisplayText {
    padding: 2rem 2rem;
  }
}

@media only screen and (max-width: 475px) {
  .imageDisplayText {
    padding: 1.5rem 1.5rem;
  }
}
