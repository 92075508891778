.hero-image {
  width: 100%;
}

.product1Text {
  font-size: 18px;
  color: white;
}

.product1TextContainer {
  border-radius: 3rem;
  background-color: #d2ab67;
}

.product1-image {
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.product1TextHeading {
  font-size: 18px;
  font-weight: 600;
  padding: 0.6rem 0rem 0.3rem 0rem;
}

.product1TextDescription {
  font-size: 14px;
}

.block-main-product1 {
  min-width: 140px;
}

.product1-image-text {
  position: absolute;
  top: 20%;
  left: 7%;
  color: white;
  width: 20%;
  text-align: left;
}

.product1-image-subtext {
  font-size: 1.2rem;
}

.product1-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
}

.product1-container {
  padding: 0rem 3rem;
}

.block-container-product1 {
  justify-content: center;
  gap: 3rem 9rem;
  padding: 0rem 7rem;
}

@media only screen and (max-width: 1200px) {
  .hero-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 45% center;
  }

  .product1-image-text {
    top: 40%;
    left: 5%;
    width: 25%;
  }

  .product1Text {
    font-size: 16px;
  }

  .block-container-product1 {
    gap: 3rem 7rem;
    padding: 0rem 7rem;
  }
}

@media only screen and (max-width: 992px) {
  .product1Text {
    padding: 2rem 1rem !important;
  }

  .block-container-product1 {
    gap: 3rem 6rem;
    padding: 0rem 5rem;
  }
}

@media only screen and (max-width: 768px) {
  .product1-image-text {
    width: 28%;
  }

  .product1-image-subtext {
    font-size: 17px;
  }

  .product1-image {
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 0rem;
  }

  .block-container-product1 {
    gap: 2rem 4rem;
    padding: 0rem 5rem;
  }

  .block-main-product1 {
    min-width: 150px;
  }
}

@media only screen and (max-width: 576px) {
  .product1-image-text {
    top: 40%;
    width: 35%;
  }

  .product1-image-subtext {
    font-size: 17px;
  }

  .product1Text {
    font-size: 14px;
  }

  .product1TextContainer {
    border-radius: 2rem;
  }

  .product1-image {
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  .product1Text {
    padding: 1rem 1rem !important;
  }

  .product1-container {
    gap: 0rem 3rem;
    padding: 0rem 3rem;
  }

  .block-main-product1 {
    min-width: 80px;
    margin-bottom: 1rem !important;
  }
  .block-container-product1 {
    gap: 2rem 4rem;
    padding: 0rem 3rem;
  }

  .block-main-product1 {
    max-width: 130px;
  }

  .product1TextHeading {
    font-size: 16px;
  }

  .product1TextDescription {
    font-size: 12px;
  }
}

@media only screen and (max-width: 420px) {
  .block-container-product1 {
    gap: 2rem 2rem;
    padding: 0rem 2rem;
  }

  .block-main-product1 {
    max-width: 120px;
  }

  .product1TextHeading {
    font-size: 15px;
  }
}
