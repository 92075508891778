.carousel-item1-image,
.carousel-item2-image,
.carousel-item3-image {
  width: 100%;
}

.carousel1-text-container {
  position: absolute;
  top: 20%;
  left: 9%;
  color: white;
  width: 24%;
  text-align: left;
}

.carousel1-text-heading {
  font-size: 2.3rem;
}

.carousel1-text-desc {
  font-size: 1.2rem;
}

.carousel2-text-container {
  position: absolute;
  top: 25%;
  left: 14%;
  color: black;
  width: 30%;
  text-align: left;
}
.carousel2-text-heading {
  font-weight: 700;
  font-size: 2.3rem;
}

.carousel2-text-desc {
  font-size: 1.2rem;
}

.carousel3-text-container {
  position: absolute;
  top: 30%;
  left: 10%;
  color: white;
  width: 40%;
  text-align: left;
}

.carousel3-text-heading {
  /* font-weight: 700; */
  font-size: 2.3rem;
  position: relative;
}

.carousel3-text-desc {
  font-size: 1rem;
}

.styled-text {
  position: relative;
  text-decoration: line-through;
  text-decoration-color: #d2ab67;
  text-decoration-thickness: 3px;
}

.what {
  font-size: 2rem;
  position: absolute;
  bottom: 40px;
  left: -4px;
  text-align: center;
  padding: 0 5px;
  color: #d2ab67;
}

.arrow {
  position: relative;
  color: #d2ab67;
  padding-left: 6px;
  font-weight: 500;
}

.also {
  font-size: 2rem;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1200px) {
  .carousel-item1-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 40% center;
  }

  .carousel1-text-container {
    top: 60%;
    left: 10%;
    width: 28%;
  }

  .carousel1-text-heading {
    font-size: 2rem;
  }

  .carousel1-text-desc {
    font-size: 1rem;
  }

  .carousel-item2-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 60% center;
  }

  .carousel2-text-container {
    top: 25%;
    left: 15%;
    width: 24%;
  }

  .carousel2-text-heading {
    font-size: 2rem;
  }

  .carousel2-text-desc {
    font-size: 1rem;
  }

  .carousel-item3-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 48% center;
  }
}

@media only screen and (max-width: 992px) {
  .carousel-item1-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 40% center;
  }
  .carousel1-text-container {
    top: 60%;
    left: 12%;
    width: 30%;
  }

  .carousel1-text-heading {
    font-size: 1.8rem;
  }

  .carousel1-text-desc {
    font-size: 0.9rem;
  }

  .carousel-item2-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 60% center;
  }
  .carousel2-text-container {
    top: 25%;
    left: 15%;
    width: 24%;
  }

  .carousel2-text-heading {
    font-size: 1.8rem;
  }

  .carousel2-text-desc {
    font-size: 0.9rem;
  }

  .carousel-item3-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 40% center;
  }

  .carousel3-text-heading {
    font-size: 2rem;
  }

  .carousel3-text-container {
    top: 33%;
    width: 50%;
  }

  .what {
    font-size: 1.9rem;
    position: absolute;
    bottom: 40px;
    left: -4px;
    text-align: center;
    padding: 0 5px;
    color: #d2ab67;
  }

  .arrow {
    position: relative;
    display: inline-block;
    color: #d2ab67;
    padding-left: 5px;
    padding-right: 5px;
  }

  .also {
    font-size: 1.9rem;
    position: absolute;
    bottom: 40px; /* Adjust this value as needed to position it above the arrow */
    left: 50%; /* Position it in the middle horizontally */
    transform: translateX(-50%); /* Center it horizontally */
  }
}

@media only screen and (max-width: 768px) {
  .carousel-item1-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 40% center;
  }

  .carousel1-text-container {
    top: 63%;
    left: 12%;
    width: 34%;
  }

  .carousel1-text-heading {
    font-size: 1.4rem;
  }

  .carousel1-text-desc {
    font-size: 0.8rem;
  }

  .carousel-item2-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 60% center;
  }

  .carousel2-text-container {
    top: 27%;
    left: 13%;
    width: 26%;
  }

  .carousel2-text-heading {
    font-size: 1.4rem;
  }

  .carousel2-text-desc {
    font-size: 0.8rem;
  }

  .carousel-item3-image {
    width: 100%;
    height: 97lvh;
    object-fit: cover;
    object-position: 35% center;
  }

  .carousel3-text-container {
    top: 38%;
    width: 50%;
  }

  .carousel3-text-heading {
    font-size: 1.5rem;
  }

  .what {
    font-size: 1.4rem;
    position: absolute;
    bottom: 28px;
    left: -4px;
    text-align: center;
    padding: 0 5px;
    color: #d2ab67;
  }

  .arrow {
    position: relative;
    display: inline-block;
    color: #d2ab67;
    padding-left: 5px;
    padding-right: 5px;
  }

  .also {
    font-size: 1.4rem;
    position: absolute;
    bottom: 28px; /* Adjust this value as needed to position it above the arrow */
    left: 50%; /* Position it in the middle horizontally */
    transform: translateX(-50%); /* Center it horizontally */
  }

  .styled-text {
    text-decoration-thickness: 2.5px;
  }
}

@media only screen and (max-width: 576px) {
  .carousel-item1-image {
    width: 100%;
    height: 90lvh;
    object-fit: cover;
    object-position: 40% center;
  }

  .carousel1-text-container {
    top: 64%;
    left: 12%;
    width: 55%;
  }

  .carousel-text-h1eading {
    font-size: 1.3rem;
  }

  .carousel1-text-desc {
    font-size: 0.8rem;
  }

  .carousel-item2-image {
    width: 100%;
    height: 90lvh;
    object-fit: cover;
    object-position: 58% center;
  }

  .carousel2-text-container {
    top: 52%;
    left: 12%;
    width: 34%;
  }

  .carousel2-text-heading {
    font-size: 1.3rem;
  }

  .carousel2-text-desc {
    font-size: 0.8rem;
  }

  .carousel-item3-image {
    width: 100%;
    height: 90lvh;
    object-fit: cover;
    object-position: 30% center;
  }

  .carousel3-text-container {
    top: 38%;
    width: 80%;
  }

  .carousel3-text-heading {
    font-size: 1.3rem;
  }

  .what {
    font-size: 1.2rem;
    position: absolute;
    bottom: 23px;
    left: -4px;
    text-align: center;
    padding: 0 5px;
    color: #d2ab67;
  }

  .arrow {
    position: relative;
    display: inline-block;
    color: #d2ab67;
    padding-left: 5px;
    padding-right: 5px;
  }

  .also {
    font-size: 1.2rem;
    position: absolute;
    bottom: 23px; /* Adjust this value as needed to position it above the arrow */
    left: 50%; /* Position it in the middle horizontally */
    transform: translateX(-50%); /* Center it horizontally */
  }

  .styled-text {
    text-decoration-thickness: 2px;
  }
}
